import { webComponent } from "#/assets/widgets-wc.js";
import { useEffect, useRef } from 'react';
import { getCurrentJwt } from './(unauthorized)/authentication/jwt';
import env from "./env";
import { logError } from "./lib/logger";

export type SsoToken = {
    FrontgateAuthenticationToken: string;
    FrontgateHost: string;
}

export default function FactSetLoader({guest} : {guest?: boolean}) {
    const lock = useRef(false);

    useEffect(() => {
        if (!lock.current && !customElements.get('fds-example-mini-time-frame-chart')) {
            lock.current = true;
            (async function() {
                try {
                    const url = `${env.gatewayBaseUrl}/marketresearch/v1/SSO` + (guest ? '/guest' : '');
                    const data: SsoToken = await fetch(url, {
                        method: "POST", 
                        headers: guest ? {} : {
                             Authorization: `Bearer ${getCurrentJwt().jwtAsString}`
                            }}).then(x=> x.json() as Promise<SsoToken>);
                    webComponent({
                        token: data.FrontgateAuthenticationToken,
                        frontgate: {
                            host: data.FrontgateHost,
                            deployment_stage: 40
                        },
                        casVersion: 'v1'
                    });
                } catch(e) {
                    logError(e);
                }
            })()
        }
    }, [guest]);

    return null;
}
